'use strict';

import $ from 'jquery';

export default class BackToTop {
  constructor() {

    let $backToTopBtn = $('.back-to-top'),
    $siteFooter = $('.site-footer');

    $.fn.isInViewport = function() {
      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight();

      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();

      return elementBottom > viewportTop && elementTop < viewportBottom;
    };


    $backToTopBtn.click(function(e) {
      e.preventDefault();

      $('html, body').animate({ scrollTop: 0 }, 'slow');

    });


    /** HANDLE BACK TO TOP VISIBILITY AND PLACEMENT ON SCROLL **/
    $(window).on('scroll', function() {

      // 1) toggle show class depending on scroll distance from top
      let verticalScrollPos = $(window).scrollTop();

      if (verticalScrollPos > 10) {
        $backToTopBtn.addClass('show');
      } else {
        $backToTopBtn.removeClass('show');
      }


      // 2) toggle fixed class depending on whether footer is showing
      if ($siteFooter.isInViewport()) {
        $backToTopBtn.addClass('fixed');
      } else {
        $backToTopBtn.removeClass('fixed');
      }

    });




  }
}
