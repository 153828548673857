'use strict';

import $ from 'jquery';

export default class SiteHeader {
  constructor() {

    window.isMenuOpen = false;
    
    let $siteHeaderParent = $('.site-header'),
    $siteHeaderHamburger = $('.site-header__hamburger'),
    $navigation = $('.site-header__navigation'),
    $closeBtn = $('.site-header__mobile--close');

    $siteHeaderHamburger.bind('click touchstart', function(e) {
      e.preventDefault();

      $siteHeaderHamburger.addClass('active');

      $navigation.addClass('show');
    });


    $closeBtn.bind('click touchstart', function(e) {

      e.preventDefault();

      $('.navigation__lvl1--link').removeClass('active');
      $('.navigation__lvl2--container').removeClass('show');

      $siteHeaderHamburger.removeClass('active');

      $navigation.removeClass('show');
      
    });


    $(window).on('load scroll', function() {

      let scrollTop = $(this).scrollTop(),
      marginTop = $siteHeaderParent.outerHeight();


      if (scrollTop > 0) {

        $siteHeaderParent.addClass('is-fixed').removeClass('site-header__home');

        if (!$siteHeaderParent.hasClass('is-home')) {
          $('main').css('margin-top', `${marginTop}px`);
        }

      } else {

        $siteHeaderParent.removeClass('is-fixed');
        $('main').css('margin-top', 0);

        if ($siteHeaderParent.hasClass('is-home')) {

          $siteHeaderParent.addClass('site-header__home');
          
        }


      }

    });



  }
}
