'use strict';

import $ from 'jquery';



export default class HomeModal {
  constructor() {
    
    let $closeBtn = $('.home-modal__modal--close'),
    $videoIframe = $('.home-modal__modal--video'),
    $homeModal = $('.home-modal');


    $closeBtn.on('click', function(e) {
      e.preventDefault();

      $homeModal.removeClass('is-visible');

    });

    // $homeModal.on('click', function(e) {
    //   e.preventDefault();

    //   $(this).removeClass('is-visible');

    // });


    if ($videoIframe.length) {

      let $parent = $('.home-modal__modal--content');

      let width = $parent.outerWidth(),
      height = $parent.outerHeight();

      $videoIframe.attr({
        width: width,
        height: height
      });
      

    }






  }
}
