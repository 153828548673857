"use strict";

import $ from 'jquery';

export default class LocationMap {
  constructor() {
    this.name = "location-map";
    console.log("%s module", this.name.toLowerCase());



    // function initMap() {
      
    // }
  }
}
