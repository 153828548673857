'use strict';

import $ from 'jquery';

export default class FormModal {
  constructor() {
    
    let $closeBtn = $('.form-modal__modal--close'),
    $videoIframe = $('.form-modal__modal--video'),
    $homeModal = $('.form-modal');


    $closeBtn.on('click', function(e) {
      e.preventDefault();

      $homeModal.removeClass('is-visible');

    });

    $homeModal.on('click', function(e) {
      e.preventDefault();

      $(this).removeClass('is-visible');

    });


    if ($videoIframe.length) {

      let $parent = $('.form-modal__modal--content');

      let width = $parent.outerWidth(),
      height = $parent.outerHeight();

      $videoIframe.attr({
        width: width,
        height: height
      });
      

    }



  }
}
