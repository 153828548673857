// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import Emitter from 'tiny-emitter';
import ObjectFitImages from 'object-fit-images';
import '@fancyapps/fancybox';
import 'jquery-match-height';

import Sorter from '../_modules/atoms/sorter/sorter';
import Navigation from '../_modules/molecules/navigation/navigation';
import Gallery from '../_modules/molecules/gallery/gallery';
import ContactForm from '../_modules/molecules/contact-form/contact-form';
import FormModal from '../_modules/molecules/form-modal/form-modal';
import CustomSelect from '../_modules/atoms/custom-select/custom-select';

import HomeBanner from '../_modules/organisms/home-banner/home-banner';
import LatestProjects from '../_modules/organisms/latest-projects/latest-projects';
import LatestNews from '../_modules/organisms/latest-news/latest-news';
import LocationMap from '../_modules/organisms/location-map/location-map';
import StandardBanner from '../_modules/organisms/standard-banner/standard-banner';
import ProjectAbout from '../_modules/organisms/project-about/project-about';
import AboutUs from '../_modules/organisms/about-us/about-us';
import RegistrationPage from '../_modules/organisms/registration-page/registration-page';

import SiteFooter from '../_modules/organisms/site-footer/site-footer';
import SiteHeader from '../_modules/organisms/site-header/site-header';

import HomeModal from '../_modules/organisms/home-modal/home-modal';
import HomeCarouselModal from '../_modules/organisms/home-carousel-modal/home-carousel-modal';
import ProjectContainer from '../_modules/organisms/project-container/project-container';

import FeaturedProjects from '../_modules/organisms/featured-projects/featured-projects';
import CareerListing from '../_modules/organisms/career-listing/career-listing';


//test

$(() => {

  let config = {
    breakpoints: {
      tablet: 768, 
      desktop: 1024,
      lgDesktop: 1280
    }
  };

  window.emitter = new Emitter();

  new ObjectFitImages();

  $('.match-height').matchHeight();

  
  new SiteFooter();
  new SiteHeader();

  new Navigation();

  if ($('.contact-form').length){
		new Sorter();

    new ContactForm();
  }

  if ($('.home-modal').length) {
    new HomeModal();
  }

  if ($('.home-modal-carousel').length) {
    new HomeCarouselModal();
  }

  if ($('.form-modal').length) {
    new FormModal();
  }

  if ($('.banner-carousel').length) {
    new HomeBanner();
  }

  if ($('.latest-projects').length) {
    new LatestProjects();
  }

  if ($('.latest-news').length) {
    new LatestNews();
  }

  if ($('.contact-us').length) {

    new LocationMap();

  }


  if ($('.featured-projects').length) {

    new FeaturedProjects();

  }


  if ($('.standard-banner').length) {
    new StandardBanner();
  }

  if ($('.project-about').length) {
    new ProjectAbout();
  }


  if ($('.about-us').length) {
    new AboutUs();
  }

  if($('.gallery').length) {
    new Gallery(config);
  }

  if($('.registration-page').length) {
    new RegistrationPage(config);
  }

  if($('.project-container').length) {
    new ProjectContainer();
  }

  $('.custom-select').map((i, ele) => {
    new CustomSelect($(ele));
  });

  if ($('.career-listing').length) {
    new CareerListing();
  }




});
