'use strict';

import $ from 'jquery';
import 'slick-carousel';

export default class LatestProjects {
  constructor() {

    let $latestProjectsSlick = $('.latest-projects__slick');

    $latestProjectsSlick.slick({
      dots: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style=""><i class="icon icon-arrow-right"></i></button>',
      prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><i class="icon icon-arrow-left"></i></button>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    })

  }
}
