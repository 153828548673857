'use strict';

import $ from 'jquery';
import 'slick-carousel';

export default class ProjectAbout {
  constructor() {
    
    let $projectAboutSlick = $('.project-about__slick');

    $projectAboutSlick.slick({
      arrows: false,
      dots: false,
      centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });


    $projectAboutSlick.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
    

      let slideDisplay = nextSlide + 1;

      $('.project-about__more--count').text(`${slideDisplay}/${slick.slideCount}`);

    })

    // $projectAboutSlick.slick('resize');

  }
}
