'use strict';

import $ from 'jquery';
import 'slick-carousel';

export default class StandardBanner {
  constructor() {
    
    let $standardBannerSlick = $('.standard-banner__slick');

    if ($standardBannerSlick.length) {

      $standardBannerSlick.slick({
        slidesToScroll: 1,
        slidesToShow: 1,
        prevArrow: `
        <button class="slick-arrow slick-prev">
          <i class="icon icon-arrow-left"></i>
        </button>`,
        nextArrow: `
        <button class="slick-arrow slick-next">
          <i class="icon icon-arrow-right"></i>
        </button>`,
        dots: true,
        dotsClass: 'slick-dots container'
      })

    }

  }
}
