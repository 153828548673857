'use strict';

import $ from 'jquery';

export default class AboutUs {
  constructor() {
    
    let $selectContainer = $('.about-us__select'),
    $a = $selectContainer.find('a'),
    $milestones = $('.about-us__milestone'),
    $prevBtn = $('#prevYear'),
    $nextBtn = $('#nextYear');



    $a.on('click', function(e) {

      e.preventDefault();


      let year = $(this).data('year');

      $milestones.removeClass('show');

      $a.removeClass('active');
      $(this).addClass('active');
      

      $(`.about-us__milestone[data-show="${year}"]`).addClass('show');

    });


    $prevBtn.on('click', function(e) {

      let $activeYear = $('.about-us__select a.active');


      let $prev = $activeYear.prev('a');

      if ($prev.length) {
        let year = $prev.data('year');

      
        $a.removeClass('active');
        $prev.addClass('active');
  
        $milestones.removeClass('show');
        $(`.about-us__milestone[data-show="${year}"]`).addClass('show');
      }
      

    });

    $nextBtn.on('click', function(e) {

      let $activeYear = $('.about-us__select a.active');

      
      let $next = $activeYear.next('a');

      if ($next.length) {
        let year = $next.data('year');

      
        $a.removeClass('active');
        $next.addClass('active');
  
        $milestones.removeClass('show');
        $(`.about-us__milestone[data-show="${year}"]`).addClass('show');
      } 

      

    });


  }
}
