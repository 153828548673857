'use strict';

import $ from 'jquery';
import 'jquery-match-height';
import 'slick-carousel';
import enquire from 'enquire.js';


export default class LatestNews {
  constructor() {

    let $matchHeight = $('.latest-news__each--content'),
    $latestNewsSlick = $('.latest-news ul');

    $matchHeight.matchHeight();


    enquire.register("screen and (max-width:768px)", {

      // OPTIONAL
      // If supplied, triggered when a media query matches.
      match : function() {

        $latestNewsSlick.slick({
          dots: false,
          arrows: true,
          nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style=""><i class="icon icon-arrow-right"></i></button>',
          prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><i class="icon icon-arrow-left"></i></button>',
        });
        
      },
  
      // OPTIONAL
      // If supplied, triggered when the media query transitions
      // *from a matched state to an unmatched state*.
      unmatch : function() {
        $latestNewsSlick.slick('destroy');
      },
  
      // OPTIONAL
      // If supplied, triggered once, when the handler is registered.
      setup : function() {},
  
      // OPTIONAL, defaults to false
      // If set to true, defers execution of the setup function
      // until the first time the media query is matched
      deferSetup : true,
  
      // OPTIONAL
      // If supplied, triggered when handler is unregistered.
      // Place cleanup code here
      destroy : function() {}
  
  });

  }
}
