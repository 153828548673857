'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';

export default class Navigation {
  constructor() {

    let $lvl1Link = $('.navigation__lvl1--link'),
    $span = $lvl1Link.find('> span'),
    $lvl2Containers = $('.navigation__lvl2--container');
    

    enquire.register("screen and (max-width:1024px)", {

      // OPTIONAL
      // If supplied, triggered when a media query matches.
      match : function() {


        $lvl1Link.on('click touchstart', function(e) {

          e.preventDefault();

          let $this = $(this),
          $lvl2 = $this.siblings('.navigation__lvl2--container');

          // $lvl2Containers.removeClass('show');
          $this.toggleClass('active');
          $lvl2.toggleClass('show');

        });


        $span.on('click touchstart', function() {

          let $parentLink = $(this).closest('.navigation__lvl1--link'),
          href = $parentLink.attr('href'); 

          window.location = href;

        })


      },
  
      // OPTIONAL
      // If supplied, triggered when the media query transitions
      // *from a matched state to an unmatched state*.
      unmatch : function() {},
  
      // OPTIONAL
      // If supplied, triggered once, when the handler is registered.
      setup : function() {},
  
      // OPTIONAL, defaults to false
      // If set to true, defers execution of the setup function
      // until the first time the media query is matched
      deferSetup : true,
  
      // OPTIONAL
      // If supplied, triggered when handler is unregistered.
      // Place cleanup code here
      destroy : function() {}
  
  });

  }
}
