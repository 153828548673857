'use strict';

import $ from 'jquery';
import 'slick-carousel';

export default class HomeBanner {
  constructor() {
    
    let $homeBannerSlick = $('.home-banner__slick');

    if ($homeBannerSlick) {
      $homeBannerSlick.slick({
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000
      });
    }
    
    let $micrositeSlick = $('.microsite-banner__slick');

    if ($micrositeSlick) {
      $micrositeSlick.slick({
        arrows: false,
        dots: true,
        slidesToShow: 1,
        autoplay: true,
        vertical: true,
        slidesToScroll: 1,
        swipeToSlide: true,
        verticalScrolling: true,
        autoplaySpeed: 3500
      });

      $micrositeSlick.on('wheel', (function(e) {
        e.preventDefault();
      
        if (e.originalEvent.deltaY < 0) {
          $(this).slick('slickPrev');
        } else {
          $(this).slick('slickNext');
        }
      }));

    }
  }
}
