'use strict';

import BackToTop from '../../atoms/back-to-top/back-to-top';

export default class SiteFooter {
  constructor() {

    new BackToTop();

  }
}
