'use strict';

import $ from 'jquery';
import '@fancyapps/fancybox';
import 'slick-carousel';
import enquire from 'enquire.js';

export default class Gallery {
  constructor(config) {
    let { breakpoints } = config;

    let $gallerySlick = $(".gallery-list"),
        $fancyBox = $(".gallery-list .gallery-list__item"),
        $items = $(".gallery-results");

    $gallerySlick.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
        let result = $items.html('+ ' + slick.slideCount + ' more');

        $('.gallery-list .slick-slide[data-slick-index="0"] .gallery-list__item').append(result);
        
    });

    enquire.register(`screen and (min-width: ${ breakpoints.lgDesktop }px)`, {
      match: () => {

        $gallerySlick.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){

            let i = ( slick.slideCount - 4 );
            let result = $items.html('+ ' + i + ' more');
            let totalSlick = slick.slideCount;

            if (totalSlick < 6) {
              $items.addClass('hidden');
            } else {
              $items.removeClass('hidden');
              $('.gallery-list .slick-slide[data-slick-index="4"] .gallery-list__item').append(result);
            }
            
        });
      },
      unmatch: () => {
        
      }
    });

    enquire.register(`screen and (max-width: ${ breakpoints.lgDesktop -1 }px)`, {
      match: () => {

        $gallerySlick.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
          let i = ( slick.slideCount - 3 );  
          let result = $items.html('+ ' + i + ' more');
          let totalSlick = slick.slideCount;

          if (totalSlick < 5) {
            $items.addClass('hidden');
          } else {
            $items.removeClass('hidden');
            $('.gallery-list .slick-slide[data-slick-index="3"] .gallery-list__item').append(result);
          }

        });
      },
      unmatch: () => {
      }
    });

    enquire.register(`screen and (min-width:${ breakpoints.tablet }px) and (max-width: ${ breakpoints.desktop -1}px)`, {
      match: () => {

        $gallerySlick.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
          let i = ( slick.slideCount - 2 );  
          let result = $items.html('+ ' + i + ' more');
    
            $('.gallery-list .slick-slide[data-slick-index="2"] .gallery-list__item').append(result);
            
        });
      },
      unmatch: () => {


      }
    });

    enquire.register(`screen and (max-width: ${ breakpoints.tablet -1 }px)`, {
      match: () => {

        $gallerySlick.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
          let result = $items.html('View more');
  
          $('.gallery-list .slick-slide[data-slick-index="0"] .gallery-list__item').append(result);
          
        });
      },
      unmatch: () => {
      }
    });

    

    var selector = '.slick-slide:not(.slick-cloned) a';

    //Skip cloned elements
    $("[data-fancybox=gallery-image]").fancybox({
      selector : selector,
      hash: false,
      backFocus : false,
      animationEffect : "fade"
    });
    
    $gallerySlick.slick({
      slidesToShow   : 5,
      slidesToScroll : 5,
      infinite : false,
      dots     : false,
      arrows   : false,
      draggable: false,
      responsive : [
        {
          breakpoint : 1280,
          settings : {
            slidesToShow   : 4,
            slidesToScroll : 4
          }
        },
        {
          breakpoint : 1024,
          settings : {
            slidesToShow   : 3,
            slidesToScroll : 3
          }
        },
        {
          breakpoint : 768,
          settings : {
            slidesToShow   : 1,
            slidesToScroll : 1
          }
        }
      ]
    });

  }
}

