'use strict';

import $ from 'jquery';

export default class ContactForm {
  constructor() {
    this.name = 'contact-form';
    console.log('%s module', this.name.toLowerCase());

    $('form.contact-form__form').on('submit', function (e) {

      $(this).find('button[type=submit]').attr('disabled', true);

      let $buttonText = $(this).find('button[type=submit] span');
      let originalText = $buttonText.text();
      $buttonText.text('Processing..');
      fetch('/api/enquiry.php', {
        method: 'POST',
        body: new FormData(this)
      }).then(response => {
        if (!response.ok) {
          alert(response.statusText);
        } else {
          $('.form-modal').addClass('is-visible');
          this.reset();
        }
        grecaptcha.reset();
        $buttonText.text(originalText);
        $(this).find('button[type=submit]').attr('disabled', false);

      });
      e.preventDefault();
    });

  }
}
