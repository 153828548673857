'use strict';

import $ from 'jquery';
import 'slick-carousel';
import 'jquery-match-height';

export default class FeaturedProjects {
  constructor() {
    
    let $slickContainer = $('.featured-projects__slick'),
    $slickImages = $('.featured-projects__slick--img');


    

    $slickContainer.slick({
      slidesToScroll: 1,
      slidesToShow: 1,
      prevArrow: `
      <button class="slick-prev slick-arrow">
        <i class="icon icon-angle-left"></i>
      </button>`,
      nextArrow: `
      <button class="slick-next slick-arrow">
        <i class="icon icon-angle-right"></i>
      </button>`
    });

    $(window).on('resize load', function() {
      repositionArrows();      

    });


    function repositionArrows() {

      let windowWidth = $('body').outerWidth();

      let arrows = $('.featured-projects').find('.slick-arrow'),
      arrowHeight = arrows.outerHeight();

      let topPosition = 0.52 * windowWidth;


      topPosition = topPosition - arrowHeight;

      arrows.css('top', topPosition);


    }

    


  }
}
