'use strict';

export default class ProjectContainer {
  constructor() {
    // $('.project-container__fancybox[data-fancybox]').fancybox({
    //   toolbar  : false,
    //   smallBtn : true,
    //   iframe : {
    //     preload : false
    //   }
    // })
  }
}
