'use strict';

import $ from 'jquery';
import slick from 'slick-carousel';


export default class HomeCarouselModal {
  constructor() {

    
    $('.slide-match-height').matchHeight();
    let $homeCarouselModal = $('.home-modal-carousel');
    
    $('.home-modal-carousel--close-button').on('click', function(e) {
      e.preventDefault();
      $('.home-modal-carousel-container').removeClass('is-visible');
    });

   

    if ($homeCarouselModal) {
      $homeCarouselModal.slick({
        arrows: true,
        dots: true,
        autoplay: false,
        autoplaySpeed: 8000,
        prevArrow: $('.arrow-left', '.home-modal-carousel-container'),
        nextArrow: $('.arrow-right', '.home-modal-carousel-container'),
        appendDots: $('.slide-dots', '.home-modal-carousel-container'),
      });

      $homeCarouselModal.on('wheel', (function(e) {
        e.preventDefault();

        if (e.originalEvent.deltaY < 0) {
          $(this).slick('slickPrev');
        }
        else {
          $(this).slick('slickNext');
        }
      }));
    }


    $homeCarouselModal.on('beforeChange', function(event, slick, currentSlide, nextSlide){
      console.log(nextSlide);
      $('.slide-match-height').matchHeight();

    });

  }
}
