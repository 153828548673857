'use strict';

import $ from 'jquery';

export default class CareerListing {
  constructor() {

    $(window).on('load', function() {

      $('.career-listing-item').each((index,item) => {
        console.log(item);
        let itemTop = $(item).offset().top;

        console.log(window.innerHeight, itemTop);
        if (itemTop < window.innerHeight ) {
          setTimeout(()=> {
            $(item).addClass('showing');
          }, index * 50);
          
        }
      });
     
      
    });
    $(window).on('scroll', function() {

      $('.career-listing-item').each((index,item) => {
        console.log(item);
        let itemTop = $(item).offset().top;

        let scrollPos = $(window).scrollTop();



        console.log(window.innerHeight, itemTop);
        if (itemTop < window.innerHeight + scrollPos ) {
          setTimeout(()=> {
            $(item).addClass('showing');
          }, index * 50);
          
        }
      });
     
      
    });

  }
}
