'use strict';

import $ from 'jquery';
import Emitter from 'tiny-emitter';

export default class CustomSelect {
  /* REQUIREMENT:
  - tiny-emitter is required (https://www.npmjs.com/package/tiny-emitter)
  - check HTML structure in custom-select.pug
  - initialise by calling new CustomSelect($wrapper)
  */
  constructor($selector) {
    let $select = $('select', $selector);

    this.isShown = false;
    this.selectedOption = {};

    let $customDropdown = $('<div class="custom-select__custom-dropdown" aria-hidden="true"></div>');
    // TO FIX: custom icon to be available
    let $triggerButton = $('<button class="custom-trigger" type="button"><span class="label"></span><span class="icon icon-angle-down"></span></button>');
    let $triggerLabel = $triggerButton.find('.label');
    let $customList = $('<ul class="custom-list"></ul>');

    this.$selector = $selector;
    this.$select = $select;
    this.$customDropdown = $customDropdown;
    this.$triggerLabel = $triggerLabel;
    this.$customList = $customList;

    $triggerButton.appendTo($customDropdown);

    $select.find('option').map((i, option) => {
      let $option = $(option);

      // by default 1st option is selected
      if (i == 0) {
        this.selectedOption['value'] = $option.val();
        this.selectedOption['name'] = $option.text();
      }

      // overwrite the selected option defined earlier
      if ($option.attr('selected')) {
        this.selectedOption['value'] = $option.val();
        this.selectedOption['name'] = $option.text();
      }

      // Create custom list
      let $customOption = $(`<li data-value="${ $option.val() }"></li>`);
      let $optionButton = $(`<button type="button">${ $option.text() }</button>`);

      $optionButton.appendTo($customOption);
      $customOption.appendTo($customList);

      $optionButton.on('click', e => {
        this.selectedOption['value'] = $option.val();
        this.selectedOption['name'] = $option.text();
        this.updateDropdown();
        this.updateSelectField();
        this.hideList();
        $select.trigger('change');
      });
    });

    this.updateDropdown();
    this.updateSelectField();

    $customList.appendTo($customDropdown);
    $customDropdown.appendTo($selector);

    $select.on('change.customSelect', e => {
      if ($select.val() == null) {
        // to set default of value to be first option of the dropdown
        this.selectedOption['value'] = $select.find('option').eq(0).val();
        this.selectedOption['name'] = $select.find('option').eq(0).text();
      } else {
        this.selectedOption['value'] = $select.val();
        this.selectedOption['name'] = $select.find(`option[value="${ $select.val() }"]`).text();
      }
      this.updateDropdown();
    });

    $triggerButton.on('click', e => {
      e.preventDefault();

      if (this.isShown) {
        this.hideList();
      } else {
        window.emitter.emit('selectOpened', $selector);
        this.showList();
      }
    });

    // to close the select when other is being opened
    window.emitter.on('selectOpened', ($openedSelector) => {
      if ($selector != $openedSelector) {
        this.hideList();
      }
    });

    $(document).on('click.customFilter', e => {
      let $eTarget = $(e.target);

      if (!$eTarget.hasClass('custom-select__custom-dropdown') && !$eTarget.parents('.custom-select__custom-dropdown').length) {
        this.hideList();
      }
    });
  }

  showList() {
    this.isShown = true;
    this.$customDropdown.addClass('list-shown');
    this.$selector.addClass('selecting');
  }

  hideList() {
    this.isShown = false;
    this.$customDropdown.removeClass('list-shown');
    this.$selector.removeClass('selecting');
  }

  updateDropdown() {
    this.$customList.find('.active').removeClass('active');
    this.$customList.find(`[data-value="${ this.selectedOption.value }"]`).addClass('active');
    this.$triggerLabel.text(this.selectedOption.name);
  }

  updateSelectField() {
    this.$select.val(this.selectedOption.value);
  }

  getSelectedValue() {
    return this.selectedOption['value'];
  }
}
